<template>
    <ValidationObserver ref="form">
        <form class="form" autocomplete="off">
        <div v-if="data.request_confirm" class="form-row">
            <div class="col-md-3 mb-3">
                <label class="form-label">Họ và tên:</label>
              <ValidationProvider name="Tên nhân sự" rules="required"
                                  v-slot="{ errors, classes }">
                <input type="hidden" :class="classes" name="name" ref="name">
              <div class="d-flex align-items-center h-37 edit-name" contenteditable="true" style=""> {{ data.request_confirm && data.request_confirm.name }} </div>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty"
                       class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-3 mb-3">
                <label class="form-label">Ngày sinh:</label>
                <div class="d-flex align-items-center h-37"> {{ data.request_confirm && data.request_confirm.birthday }} </div>
            </div>
            <div class="col-md-3 mb-3">
                <label class="form-label">Giới tính:</label>
                <div class="d-flex align-items-center h-37"> {{ data.request_confirm && data.request_confirm.gender }} </div>
            </div>
            <div class="col-md-3 mb-3">
                <label class="form-label">Địa chỉ:</label>
              <ValidationProvider name="Địa chỉ" rules="required"
                                  v-slot="{ errors, classes }">
                <input type="hidden" :class="classes" name="address" ref="address">
                <div class="d-flex align-items-center h-37 edit-name text-break" contenteditable="true" > {{ data.request_confirm && data.request_confirm.address }} </div>
                <div class="fv-plugins-message-container">
                  <div data-field="address" data-validator="notEmpty"
                       class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
        </div>
        <div class="form-now row">
            <el-divider v-if="data.veryfy_images_request && data.veryfy_images_request.length > 0" content-position="left">Xác minh danh tính</el-divider>
            <div class="col-md-12" v-if="data.veryfy_images_request.length === 0" >Chưa có thông tin để xác minh danh tính</div>
            <div  class="col-md-4 mb-3" v-for="(it, key) in data.veryfy_images_request" :key="key">
                <el-image @click="showImageIdentity" class="image-verify img-identity" :src="it.url">
                    <!--eslint-disable-->
                    <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </div>
            <div
                id="modal-show-img"
                class="detail-img-modal"
                onclick="this.style.display='none'"
                style="display: none"
                >
                <div class="detail-img-modal-content detail-img-animate-zoom">
                    <span
                    class="
                        detail-img-button
                        detail-img-hover-red
                        detail-img-xlarge
                        detail-img-display-topright
                    "
                    >x</span
                    >
                    <img id="img-detail" style="width: 100%" />
                </div>
            </div>
        </div>
    </form>
        <button @click="acceptUser" class="btn btn-primary float-right mt-3 ml-3" type="submit">
            <i v-if="loading.save" class="el-icon-loading"></i>
            Duyệt ngay
        </button>
        <button @click="denied" class="btn btn-outline-danger float-right mt-3 ml-3" type="submit">
            <i v-if="loading.save" class="el-icon-loading"></i>
            Từ chối
        </button>
        <button @click="close" class="btn btn-outline-default float-right mt-3" type="submit">
            <i v-if="loading.save" class="el-icon-loading"></i>
            Đóng
        </button>
    </ValidationObserver>
</template>

<script>
    import {mapGetters} from "vuex";
    import { REJECT_USER_IDENTITY ,ACCEPT_USER_IDENTITY, COUNT_IS_WAIT_USER} from "@/core/services/store/profile.module";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    export default {
        props: {
            data: Object,
        },
        data() {
            return {
                loading: {
                    save: false,
                    loadingSelect: false
                },
            }
        },
      mounted() {
      },

      computed: {
        ...mapGetters(['currentUser'])
      },

      watch: {
      },

      methods: {
          showImageIdentity(element) {
            document.getElementById("img-detail").src = element.srcElement.src;
            document.getElementById("modal-show-img").style.display = "block";
          },
          close() {
              this.$emit('close')
          },
          denied() {
              this.$prompt('Nhập lý do từ chối', 'Từ chối xác minh', {
                  confirmButtonText: 'Xác nhận',
                  cancelButtonText: 'Hủy',
                  inputPlaceholder : 'Nhập...',
                  inputValidator : (value) => {
                    if (value) return true;
                    return 'Lý do từ chối là bắt buộc';
                  }
              }).then(({ value }) => {
                  this.rejectUser(value)
              });
          },
          rejectUser(reason) {
              let params = {
                  id: this.data.id,
                  reason: reason
              };
              this.loading.save = true
              this.$store.dispatch(REJECT_USER_IDENTITY, params)
                  .then((response) => {
                      if (response.status === 422) {
                          this.$notify({
                              title: 'Warning',
                              message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                              type: 'warning'
                          });
                      } else {
                          this.$message({
                              type: 'success',
                              message: 'Từ chối xác minh thành công'
                          });
                          this.$emit('completed', response.data)
                          this.$emit('close')
                      }
                      this.loading.save = false
                  });
          },
          async acceptUser(){
              const form = new FormData();
              form.append('id', this.data.id);
              form.append('name', this.data.request_confirm.name);
              form.append('gender', this.data.request_confirm.gender);
              form.append('address', this.data.request_confirm.address);
              form.append('birthday', this.$moment(this.data.request_confirm.birthday).format('YYYY-MM-DD'));

              this.$store.dispatch(ACCEPT_USER_IDENTITY, form)
                  .then((response) => {
                      if (response.status === 422) {
                          this.$notify({
                              title: 'Warning',
                              message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                              type: 'warning'
                          });
                      } else {
                        this.$message({
                          type: 'success',
                          message: 'Nhân sự đã được xác minh danh tính thành công'
                        });
                          this.$emit('completed', response.data)
                          this.$emit('close')
                      }
                      this.loading.save = false
                  }).then(() => {
                  let params = { is_wait: 1 , is_update : true };
                  this.$store.dispatch(COUNT_IS_WAIT_USER, params);
              });
          },        

      },
    }
</script>

<style>
div.edit-name:hover {
  border-radius: 10px;
  color: #fff;
  background-color: #5c4581;
  cursor: pointer;
  padding: 10px;
}
.img-identity:hover {
  opacity: 0.5;
  cursor: pointer;
}

.detail-img-modal {
  z-index: 3;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.detail-img-xlarge {
  font-size: 24px;
}
.detail-img-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}
.detail-img-button {
  border: none;
  display: inline-block;
  padding: 4px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}
.detail-img-animate-zoom {
  animation: animatezoom 0.6s;
}
.detail-img-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}
@media (max-width: 730px) {
  .second {
    margin-left: 20px;
  }
  .detail-img-modal-content {
    width: 200px;
  }
  .detail-img-xlarge {
    font-size: 12px;
  }
  .detail-img-button {
    padding: 0px 8px;
  }
}
</style>
