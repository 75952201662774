<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tìm tên, email, sđt người đăng ký" autocomplete="false"></el-input>
              </el-col>
              <el-col v-if="isHCKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.type_identity" clearable class="w-100 mt-3" placeholder="Phân loại">
                  <el-option
                      v-for="item in optionClassify"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.status_verify" clearable class="w-100 mt-3"
                           placeholder="Chọn trạng thái xét duyệt">
                  <el-option
                      v-for="item in optionStatus"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading.search"
                        class="btn btn-primary w-100 mt-3"
                        @click="find">
                  <i v-bind:class="[loading.search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>

            </el-row>
            <el-table
                :data="tableData"
                stripe
                v-loading="loading.table"
                border
                class="table-hover mb-5 mt-5 table-responsive-lg"
                style="width: 100%">
              <el-table-column label="#" type="index" width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="code"
                  label="Mã nhân sự"
                  width="100">
                <template v-slot="scope">
                  {{ scope.row.id + 100000 }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Họ và tên"
                  width="180">
                <!--eslint-disable-->
                <template slot-scope="scope">
                  {{ scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="account_type"
                  label="Vị trí">
                <template slot-scope="scope">
                  {{ scope.row.profile.account_type.name }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="Số điện thoại">
                <template slot-scope="scope">
                  {{ scope.row.phone }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="gender"
                  label="Giới tính"
                  width="80">
                <template slot-scope="scope" >
                  <span v-if="scope.row.profile || scope.row.request_confirm">
                     {{ scope.row.profile.gender ? scope.row.profile.gender : (scope.row.request_confirm ? scope.row.request_confirm.gender : '') }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  label="Địa chỉ">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <p>Địa chỉ: </p>
                    <div slot="reference" class="name-wrapper" v-if="scope.row.profile || scope.row.request_confirm">
                      {{ scope.row.profile.address ? scope.row.profile.address : (scope.row.request_confirm.address ?? '') }}
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                  prop="birthday"
                  label="Ngày sinh">
                <template slot-scope="scope">
                  <span style="margin-left: 10px" v-if="scope.row.profile && scope.row.profile.birthday || scope.row.request_confirm && scope.row.request_confirm.birthday">{{
                      scope.row.profile.birthday ? $moment(scope.row.profile.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY') : $moment(scope.row.request_confirm.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="isHCKV"
                               label="Văn phòng">
                <template slot-scope="scope">
                  {{
                    scope.row.profile.branch.name_alias ? scope.row.profile.branch.name_alias : scope.row.profile.branch.name
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  label="Thao tác">
                <template slot-scope="scope">
                  <el-row class="action-table">
                    <el-tooltip class="cursor-pointer"
                                :content="scope.row.profile.status_verify === 3 ? 'Đã từ chối xác minh' : 'Duyệt nhân sự'"
                                placement="top">
                      <a @click="handleEdit(scope.$index, scope.row)"
                         class="btn btn-sm  " :class="{
                                                'btn-primary': scope.row.profile.status_verify !== 3,
                                                'btn-danger': scope.row.profile.status_verify === 3
                                               }">
                        <i class="fal fa-edit" :class="{
                                                'fa-edit': scope.row.profile.status_verify !== 3,
                                                'fa-times': scope.row.profile.status_verify === 3
                                               }"></i>
                        {{ scope.row.profile.status_verify === 3 ? 'Đã từ chối xác minh' : 'Duyệt' }}
                      </a>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging">
              <div class="block">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="10"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <human-item
            @completed="completedUpdate"
            @close="closeDialog"
            v-if="humanResource"
            :data="humanResource"
            :update="isUpdate"
            :join="isJoin"
            :confirm="isConfirm"
            :businessLevels=[]
        >
        </human-item>
      </el-row>
    </el-dialog>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogIdentityVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <human-identity
            @completed="completedUpdate" @close="closeDialogIdentity"
            :data="humanResource" :confirm="isConfirm"
        >
        </human-identity>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {USER_HUMAN_RESOURCE} from "@/core/services/store/profile.module";
import {BRANCH_LIST} from "@/core/services/store/service.module";
import HumanItem from "@/views/pages/human-resource/chi-tiet-nhan-su";
import HumanIdentity from "@/views/pages/human-resource/xac-minh-nhan-su";
import {HANH_CHINH_KHU_VUC} from "@/core/config/accountTypeOption";
import {mapGetters} from "vuex";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
  name: "nhan-su-hoat-dong",
  components: {
    HumanItem,
    HumanIdentity
  },
  data() {
    return {
      form: {
        name: null,
        branch_id: null,
        status_verify: null,
        type_identity: null
      },
      loading: {
        table: false,
        search: false,
        save: false,
      },
      tableData: [],
      branches: [],
      paging: {
        current_page: 1,
        per_page: 50,
        total: 1,
      },
      humanResource: [],
      dialogFormVisible: false,
      dialogIdentityVisible: false,
      dialog: {
        title: null,
        isEdit: false,
        isVerify: false,
      },
      optionStatus: [
        {id: 'active', value: 'Chờ xét duyệt'},
        {id: 3, value: 'Đã từ chối'}
      ],
      optionClassify: [
        {id: 'new', value: 'Duyệt nhân sự mới'},
        {id: 'identity', value: 'Xác minh danh tính'}
      ],
      isUpdate: false,
      isConfirm: false,
      isJoin: false,
      HANH_CHINH_KHU_VUC,
      isHCKV: false,
    }
  },
  created() {
    if (this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC) {
      this.isHCKV = true;
      this.getBranches();
    }
  },
  mounted() {
    let area = this.isHCKV ? `: ${this.currentUser.profile.area.name}` : '';
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách"},
      {title: "Nhân sự chờ duyệt" + area, icon: 'far fa-user'}
    ]);
    this.querySever();
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    closeDialog() {
      this.dialogFormVisible = false;
    },
    closeDialogIdentity() {
      this.dialogIdentityVisible = false;
    },
    completedUpdate(item) {
      let data = this.tableData.find((user) => {
        return user.id === item.id;
      });
      data.profile.status_verify = item.profile.status_verify;
      this.dialogFormVisible = false;
      if (data.status_verify === 2) {
        this.updateAfterConfirm()
      } else {
        this.querySever();
      }
    },
    updateAfterConfirm() {
      const h = this.$createElement;
      this.$msgbox({
        title: 'Thông báo',
        message: h('p', null, [
          h('span', null, 'Nhân sự đã được thêm thành công vào văn phòng'),
          h('br', null, null),
          h('span', null, 'Hãy cập nhật '),
          h('i', {style: 'color: teal'}, 'ngày bắt đầu làm việc '),
          h('span', null, 'và'),
          h('i', {style: 'color: teal'}, ' mã cấp trên '),
          h('span', null, 'cho nhân sự'),
        ]),
        showCancelButton: true,
        confirmButtonText: 'Cập nhật ngay',
        cancelButtonText: 'Hủy',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
          } else {
            done();
          }
        }
      }).then(() => {
        this.isUpdate = true;
        this.isConfirm = false;
        this.isJoin = false;
        this.dialogFormVisible = true;
        this.dialog.title = `Cập nhật thông tin nhân sự: ${this.humanResource.name}`;
      });
    },
    handleEdit(index, item) {
      if (item.is_confirm == 2) {
        if (item.profile.status_verify != 3) {
          this.dialog.title = `Duyệt xác minh danh tính: ${item.name}`;
          this.dialogIdentityVisible = true;
          this.humanResource = this.tableData[index];
          this.humanResource.index = index;
        }
      } else {
        this.dialog.title = `Duyệt thông tin nhân sự: ${item.name}`;
        this.dialogFormVisible = true;
        this.humanResource = this.tableData[index];
        this.isUpdate = true;
        this.isConfirm = false;
        this.isJoin = true;
        this.humanResource.index = index;
      }
    },
    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    find() {
      this.paging.current_page = 1;
      this.querySever();
    },
    querySever(customProperties) {
      this.loading.table = true;
      this.loading.search = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(USER_HUMAN_RESOURCE, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.tableData = response.data.data;
              this.paging = response.data.meta.pagination;
            }
            this.loading.table = false;
            this.loading.search = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
        is_wait: 1,
        area_id: this.currentUser.profile.khuvuc_id,
        is_get_student : true
      };
      if (this.form.name) {
        params = _.merge(params, {key_word: this.form.name})
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.status_verify) {
        params = _.merge(params, {status_verify: this.form.status_verify})
      }
      if (this.form.type_identity) {
        params = _.merge(params, {type_identity: this.form.type_identity})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    getBranches() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              // this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              // this.querySever({branch_id: this.form.branch_id});
            }
          })
    },
  },

}
</script>
